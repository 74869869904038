export const initialFeaturesSet = [
    {
        question:"mvm",
        calculate:false,
        questionid:""
    },
    {
        question:"svdm",
        calculate:false,
        questionid:""
    },
    {
        question:"mangle",
        calculate:false,
        questionid:""
    },
    {
        question:"sdangle",
        calculate:false,
        questionid:""
    },
    {
        question:"df",
        calculate:false,
        questionid:""
    },
    {
        question:"fpdf",
        calculate:false,
        questionid:""
    },
    {
        question:"p625",
        calculate:false,
        questionid:""
    },
    {
        question:"gps",
        calculate:false,
        questionid:""
    },
    {
        question:"battery",
        calculate:false,
        questionid:""
    },
    {
        question:"activity_min",
        calculate:false,
        questionid:""
    },
    {
        question:"caloriesBurnt",
        calculate:false,
        questionid:""
    },
    {
        question:"steps",
        calculate:false,
        questionid:""
    },
    {
        question:"distance Travelled",
        calculate:false,
        questionid:""
    },
    {
        question:"flight climbed",
        calculate:false,
        questionid:""
    },
    {
        question:"avgHeartRate",
        calculate:false,
        questionid:""
    },
    {
        question:"restingHeartRate",
        calculate:false,
        questionid:""
    },
    {
        question:"walkingspeed",
        calculate:false,
        questionid:""
    },

]